<script>
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';

import {
  camelCaseKeys,
  DELAY,
  exportFile,
  formatDate,
  isNullValue,
  LOG_TYPE,
  logger,
  navigationErrorHandler,
} from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/vue-internal';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { MIME_TYPES } from '@/constants/mimeTypes';
import { USER_STATUS } from '@/constants/userStatus.const';

import { getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';
import { fetchUserDetails, userCompanyUuid, userUuid } from '@/stores/User/UserData/UserDataMapper';
import {
  downloadInvoices,
  invoicesDownloading,
  invoicesSelected,
  rawInvoicesPdf,
  toggleInvoiceSelection,
  unselectAllInvoices,
} from '@/stores/Invoices/InvoicesMapper';

import { parseApiErrorMessage } from '@/utils/apiHelpers';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useAlgolia } from '@/composable/Algolia/useAlgolia';

import AlertComponent from '@/components/Alert/AlertComponent';

import PaymentMethodsHub from '@Shared/Payments/PaymentMethodsHub/PaymentMethodsHub';
import {
  ACTIONS,
  HUB_REDIRECT_FLOW_STATUS,
  PAYMENT_REDIRECTED,
  RESPONSE_STATUS,
  SOURCE_PAGES,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';

import ALERT_TYPES from '@/components/Alert/alertTypes';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';

import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';

import { storage } from '@Shared/Payments/PaymentMethodsHub/composables/storage';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';

import { removeUrlQuery } from '@Shared/Payments/PaymentMethodsHub/composables/responseUtils';
import MigrationStatementModal from './MigrationStatementModal';
import PayInvoiceModal from './PayInvoiceModal';
import {
  downloadedPDFFilename,
  downloadedZIPFilename,
  getFacetsConfigs,
  getTableConfig,
} from './helpers/invoicesTableConfig';
import { LABELS } from './constants/invoicesTable.const';

export default {
  name: 'InvoicesTable',

  components: {
    FeedbackModalComponent,
    MigrationStatementModal,
    MuiAlgoliaList,
    PayInvoiceModal,
    AlertComponent,

    PaymentMethodsHub,
  },

  inject: ['paymentSuccess'],

  props: {
    hasFilters: {
      type: Boolean,
      default: false,
    },

    hasChecks: {
      type: Boolean,
      default: false,
    },
  },

  setup(_props, context) {
    const {
      apiKey,
      algoliaConfig,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
    } = useAlgolia();

    const { notifyError } = useNotifications();

    const { checkPaymentIsFinalStatus, setNewResponse, storeData } = commonImplementationHelper(context);

    const { getAPIFromProvider, getProfileInfo } = PaymentMethodsHubComposable(storeData);

    const { isBusinessProfile } = getProfileInfo();
    const app = document.getElementById('app');

    const adyenProviderVersion = 'Adyen/5.59.0';

    return {
      apiKey,
      algoliaConfig,
      notifyError,
      initAlgolia,
      refreshAlgoliaTable,
      getAlgoliaLabels,
      app,
      LABELS,

      checkPaymentIsFinalStatus,
      setNewResponse,
      isBusinessProfile,

      getAPIFromProvider,
      adyenProviderVersion,

      ACTIONS,
      ALERT_TYPES,
    };
  },

  data() {
    return {
      isPageLoading: false,
      isListLoading: false,
      isMigrationStatementModalOpen: false,
      isPayInvoiceB2BModalOpen: false,
      invoiceUuid: null,
      invoiceDate: null,
      modals: {
        active: {
          isOpen: false,
        },
      },

      errorMessage: null,
      isPaymentMethodValid: false,
      currentPaymentMethod: null,
      PaymentResponse: null,
      isPaymentLoading: false,
      isPaymentModalOpen: false,
    };
  },

  computed: {
    userUuid,
    userCompanyUuid,
    isBusinessProfileActive,

    invoicesSelected,
    invoicesDownloading,
    rawInvoicesPdf,
  },

  watch: {
    paymentSuccess(value) {
      if (value.response.matchingName === SOURCE_PAGES.invoices) {
        if (value.isValid) {
          this.closePSD2Modal();
          this.showSuccessFeedback();
        }

        // only show refused feedback upon redirect or challenge/identify flows, as the rest will be shown on the payment method modal itself
        const additionalStepResponses = [RESPONSE_STATUS.challenge, RESPONSE_STATUS.identify];

        if (
          !value.isValid && (storage.get(PAYMENT_REDIRECTED)
          || additionalStepResponses.includes(value.response.loopOrigin)
          )) {
          this.closePSD2Modal();
          this.showRefusedFeedback(value.response);

          storage.remove(PAYMENT_REDIRECTED);
        }
      }
    },
  },

  async created() {
    const uuidFilter = this.isBusinessProfileActive
      ? `company_uuid:'${this.userCompanyUuid}'`
      : `user_uuid:'${this.userUuid}'`;
    this.invoiceFilter = `${uuidFilter} AND is_final:true`;
    await this.initAlgolia('invoices', this.invoiceFilter);
    this.TABLE_LABELS = this.getAlgoliaLabels(this.LABELS);
    const { customer_service_email: operatorSupportEmail } = this.getCurrentCSOperator();

    this.invoiceContact = `<a href="mailto:${operatorSupportEmail}" target="_blank" class="emobg-link-primary emobg-font-weight-semibold text-underline">${operatorSupportEmail}</a>`;

    const actions = {
      onClickReference: this.showMigrationStatement,
      onClickPay: this.showPayModal,
      onClickDownloadInvoice: this.downloadInvoicePdf,
      onCheckInvoice: this.onCheckInvoice,
    };

    this.invoiceConfig = getTableConfig(actions, this.hasChecks);

    this.invoicesFacets = getFacetsConfigs();

    this.invoiceActions = [
      {
        label: `${this.$t('business_profile.action.download')} PDF`,
        action: this.downloadSelectedInvoices,
      },
    ];
    this.isPageLoading = true;
  },

  mounted() {
    this.unselectAllInvoices();
  },

  methods: {
    isEmpty,
    fetchUserDetails,
    getCurrentCSOperator,

    downloadInvoices,
    toggleInvoiceSelection,
    unselectAllInvoices,

    closePaymentModal(isPaid) {
      this.isPayInvoiceB2BModalOpen = false;

      if (isPaid) {
        this.redirectIfNotUnpaid();
      }
    },

    closeMigrationStatementModal() {
      this.isMigrationStatementModalOpen = false;
    },

    async redirectIfNotUnpaid() {
      const { status } = await this.fetchUserDetails();

      if (status !== USER_STATUS.unpaid) {
        this.$router.push({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
      } else {
        this.isListLoading = true;
        setTimeout(() => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaInvoicesList, DELAY.medium);
          this.isListLoading = false;
        }, DELAY.medium);
      }
    },

    showMigrationStatement(invoiceDate) {
      this.invoiceDate = formatDate(invoiceDate, DATETIME_FORMATS.LongMonthYear);
      this.isMigrationStatementModalOpen = true;
    },

    showPayModal(invoiceUuid) {
      this.invoiceUuid = invoiceUuid;

      if (!this.isBusinessProfile && this.$featureFlag.flags.csrev3546PayInvoiceB2C) {
        this.isPaymentModalOpen = true;
      } else {
        this.isPayInvoiceB2BModalOpen = true;
      }
    },

    onCheckInvoice(invoiceUuid) {
      this.toggleInvoiceSelection(invoiceUuid);
    },

    downloadInvoicePdf(invoiceUuid, reference) {
      const mimeType = MIME_TYPES.applicationPdf;
      const filename = `${reference}.pdf`;
      const invoices = [invoiceUuid];

      this.requestDownloadInvoices(invoices, mimeType, filename);
    },

    downloadSelectedInvoices() {
      const mimeType = this.invoicesSelected.length === 1 ? MIME_TYPES.applicationPdf : MIME_TYPES.applicationZip;
      const filename = this.invoicesSelected.length === 1 ? downloadedPDFFilename : downloadedZIPFilename;
      const invoices = [...this.invoicesSelected];

      forEach(this.invoicesSelected, this.toggleInvoiceSelection);

      this.requestDownloadInvoices(invoices, mimeType, filename);
    },

    async requestDownloadInvoices(invoices, mimeType, filename) {
      try {
        await this.downloadInvoices(invoices);
        exportFile(this.rawInvoicesPdf, filename, { type: mimeType });
      } catch (error) {
        this.notifyErrorBlock(error);
      }
    },

    notifyErrorBlock(error) {
      this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);

      this.notifyError({
        text: this.errorMessage,
        textAction: this.$t('buttons.ok'),
      });
    },

    closePSD2Modal() {
      this.errorMessage = null;
      this.$emit('close-modal');
      this.isPaymentModalOpen = false;
    },

    onPaymentMethodValid(method) {
      this.setIsPaymentValid(method.isValid);
      this.setCurrentPaymentMethod(method);
      this.setErrorMsg(null);
    },

    setErrorMsg(message) {
      this.errorMessage = message;
    },

    setIsPaymentValid(isValid) {
      this.isPaymentMethodValid = isValid;
    },

    setIsPaymentLoading(isLoading) {
      this.isPaymentLoading = isLoading;
    },

    setCurrentPaymentMethod(method) {
      this.currentPaymentMethod = method;
    },

    async submitPayment() {
      this.setErrorMsg(null);

      const { isValid } = this.currentPaymentMethod;

      if (!isValid) {
        return;
      }

      const request = {
        ...this.currentPaymentMethod,
        invoiceUuid: this.invoiceUuid,
        paymentMethodUuid: this.currentPaymentMethod.paymentMethod.uuid,
      };

      this.setIsPaymentLoading(true);

      let originalResponse = null;

      const api = await this.getAPIFromProvider(this.adyenProviderVersion);

      try {
        originalResponse = await api.payInvoice(request);
      } catch (error) {
        logger.message(error, LOG_TYPE.error);

        originalResponse.providerStatus = RESPONSE_STATUS.error;
        originalResponse.providerMessage = error.response.data.message;
      }

      removeUrlQuery(window.location.href, window.location.search);

      const response = camelCaseKeys(originalResponse);

      if (response.providerStatus === RESPONSE_STATUS.refused) {
        this.setErrorMsg(response.providerMessage);
      }

      if (
        response.providerStatus === RESPONSE_STATUS.pending
        && !this.$router.history.current.fullPath.includes(HUB_REDIRECT_FLOW_STATUS.toRedirect)
      ) {
        this.$router.push({ query: { status: HUB_REDIRECT_FLOW_STATUS.toRedirect } }).catch(navigationErrorHandler);
      }

      const matchingResponse = {
        ...response,
        matchingName: SOURCE_PAGES.invoices,
        invoiceUuid: this.invoiceUuid,
      };

      const hierarchy = {
        ancestor: SOURCE_PAGES.invoices,
        parent: this.invoiceUuid,
      };

      const finalResponse = this.setNewResponse(matchingResponse, response.providerStatus, hierarchy);

      if (isNullValue(finalResponse)) {
        this.setIsPaymentLoading(false);
      } else {
        this.PaymentResponse = finalResponse;
      }
    },

    showSuccessFeedback() {
      this.modals.active = {
        ...genericSuccessArgs(this.$t),
        title: this.$t('modal.confirm_payment.success.title'),
        description: this.$t('modal.confirm_payment.success.description'),
        primaryCallToAction: () => {
          this.refreshAlgoliaTable(this.$refs.muiAlgoliaInvoicesList, DELAY.medium);
          this.isListLoading = false;
          this.modals.active.isOpen = false;
          this.setIsPaymentLoading(false);
        },
        isOpen: true,
      };
    },

    showRefusedFeedback(response) {
      this.modals.active = {
        ...genericErrorArgs(this.$t),
        title: response.providerMessage || this.$t('modal.confirm_payment.error.title'),
        description: '',
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.active.isOpen = false;
          this.setIsPaymentLoading(false);
        },
      };
    },
  },
};
</script>

<template>
  <div
    data-test-id="invoices-tab"
    class="InvoicesTable"
  >
    <MuiAlgoliaList
      v-if="isPageLoading"
      ref="muiAlgoliaInvoicesList"
      :loading="isListLoading"
      :config="algoliaConfig"
      :api-key="apiKey"
      :filters="invoiceFilter"
      :facets="hasFilters ? invoicesFacets : []"
      :search-placeholder="$t('algolia_table.search_table')"
      :table-config="invoiceConfig"
      :labels="TABLE_LABELS"
      :no-data-label="$t('business_profile.invoice.no_invoice')"
      :scroll-target="app"
      index="invoices"
      class="InvoicesListTab"
    >
      <ui-dropdown
        v-if="!isEmpty(invoicesSelected)"
        slot="extraAction"
        :size="SIZES.small"
      >
        <ui-button
          slot="trigger"
          :size="SIZES.small"
          :color="GRAYSCALE.inkLight"
          :face="FACES.outline"
          square
        >
          <ui-icon
            :size="ICONS_SIZES.small"
            :color="GRAYSCALE.ink"
            :icon="ICONS.optionsHorizontalFull"
          />
        </ui-button>
        <ui-dropdown-actions
          slot="content"
          :actions.prop="invoiceActions"
        />
      </ui-dropdown>
    </MuiAlgoliaList>

    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />

    <PayInvoiceModal
      v-if="isPayInvoiceB2BModalOpen"
      :is-open="isPayInvoiceB2BModalOpen"
      :invoice-uuid="invoiceUuid"
      data-test-id="pay-modal"
      @on:close="closePaymentModal"
    />

    <ui-modal
      data-test-id="invoices-payment-modal"
      :open="isPaymentModalOpen"
    >
      <div
        slot="body"
        class="p-5 text-left"
      >
        <h2 class="pb-5">
          {{ $t('modal.confirm_payment.title') }}
        </h2>

        <AlertComponent
          v-if="errorMessage"
          :type="ALERT_TYPES.danger"
          class="w-100 mb-3"
          data-test-id="modal-error"
        >
          {{ errorMessage }}
        </AlertComponent>

        <PaymentMethodsHub
          :action="ACTIONS.select"
          :provider="adyenProviderVersion"
          :handle-external-response="PaymentResponse"
          :set-as-default="Boolean(false)"
          @update:helper-component-active="setIsPaymentValid"
          @update:payment-method-valid="onPaymentMethodValid"
          @on:payment-error="setErrorMsg"
        />
      </div>

      <div
        slot="footer"
        class="d-flex align-items-center justify-content-center
            emobg-border-top-1 emobg-border-color-ground-light px-5 py-3
            emobg-background-color-white emobg-border-radius-medium"
      >
        <ui-button
          :face="FACES.outline"
          :disabled="isPaymentLoading"
          pill
          class="emobg-color-white-contrast mr-2"
          data-test-id="cancel-button"
          @clickbutton="closePSD2Modal"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>

        <ui-button
          :disabled="!isPaymentMethodValid"
          :loading="isPaymentLoading"
          pill
          data-test-id="confirm-button"
          @clickbutton="submitPayment"
        >
          {{ $t('buttons.confirm') }}
        </ui-button>
      </div>
    </ui-modal>

    <MigrationStatementModal
      v-if="isMigrationStatementModalOpen"
      :is-open="isMigrationStatementModalOpen"
      :invoice-date="invoiceDate"
      :invoice-contact="invoiceContact"
      data-test-id="migration_statement-modal"
      @on:close="closeMigrationStatementModal"
    />
  </div>
</template>
